<template>
  <div class="header" v-if="userData">
    <span class="tjhy" @click="showRight()"><img src="@/assets/img/personal/tjhy.png" alt="" /></span>
    <div class="user-info">
      <img class="avatar" @click="showPreview" id="avatar" :src="userData.user.userImg" alt=""/>
      <div class="nick-name">{{ userData.usernameBz }}</div>
      <div class="other-info">
        <span class="focus-count"><i style="font-style: normal">{{ userData.user.fansCount }}</i>关注</span>
        <span>{{ userData.user.beLikedCount }} 点赞</span>
        <span>{{ userData.user.viewCount }} 总浏览量</span>
      </div>
    </div>
    <!--        弹窗-->
    <div class="modal1" id="modal1" v-if="showModal">
      <div class="modal-backdrop"></div>
      <div class="modal-body">
        <div class="modal-body-title">请选择用户并关注</div>
        <div class="like-user-search">
          <input type="text" placeholder="请输入好友昵称或手机号码" v-model="searchText" style="outline: none">
          <span @click="searchUser()">搜索</span>
        </div>
        <form id="userGiveForm">
          <label v-for="(item,index) in userList " :key="index">
            <input type="checkbox" v-model.number="userIdList" name="userIdList" :value="item.id" >
            <img :src="item.userImg" :alt="item.nickName">
            <span>{{item.nickName}}</span>
          </label>
        </form>
        <div class="user-button">
          <span class="user_btn" @click="DouserGive()">关注</span>
          <span class="user_btn" style="background: #999" @click="closeModal()">取消</span>
        </div>

      </div>
    </div>
    <!--先判断是否登录-->
    <div v-if="userData.myid != userData.user.id && userInfo.id" class="user-list">
      <button @click="swichFocus(userData.count == 0, userData.user.id)" class="focus" :class="userData.count == 0 ? '' : 'cancel-focus'">
        <img v-if="userData.count == 0" src="@/assets/img/personal/icon37.png" alt=""/>
        {{ userData.count == 0 ? "关注" : "取消关注" }}
      </button>
      <a v-if="id != userData.myid" href="javascript:;" rel="nofollow" class="private-chat" @click="handleFormShow">
        私信
      </a>
    </div>
    <div v-if="!userInfo.id" class="focus" @click="toLogin">
      <img src="@/assets/img/personal/icon37.png" alt="" />关注
    </div>

  </div>
  <div class="post-follow" v-if="userData">
    <!--tab栏-->
    <div v-show="userData.myid && userData.myid == userData.user.id" class="post-follow-title">
      <span class="post-follow-before"
            v-for="(item, index) in menu"
            :class="currentMenu == item ? 'post-follow-active' : ''"
            :key="index"
            @click="changeMenu(item)"
      ><a href="javascript:;">{{ item }}</a></span>
    </div>
  </div>
  <!--【我的】-->
  <personal-mine :userData="userData" :pageUserId="id" v-show="currentMenu == menu[0]"></personal-mine>
  <!--【关注】-->
  <personal-mine :userData="focusUserData" :pageUserId="id" v-show="currentMenu == menu[1]" :noInfo="menu[1]"></personal-mine>
  <!--【粉丝】-->
  <personal-mine :userData="subUserData" :pageUserId="id" v-show="currentMenu == menu[2]" :noInfo="menu[2]"></personal-mine>
  <!--【私信】-->
  <letter :userData="letterUserData" v-show="currentMenu == menu[3]"></letter>
  <!--【发帖】-->
  <post-topic-btn v-if="userData && userData.myid == userInfo.id"></post-topic-btn>
  <preview
      v-if="userData && userData.user.userImg && previewing"
      @close="closePreview"
      :imgList="[{ src: userData.user.userImg }]"
  ></preview>

  <loading :options="loadOption" v-if="loading"></loading>
  <!--【发私信】-->
  <div class="form" v-show="isWriting">
    <div class="operation">
      <div class="cancel" @click="hideForm">取消</div>
      <div class="confirm" @click="submit">发表</div>
    </div>
    <textarea
        id="comment"
        v-model="inputText"
        name="comment"
        placeholder="我也来说两句..."
    ></textarea>
  </div>
</template>


<script lang="ts">
import {
  defineComponent,
  ref,
  inject,
  onActivated,
  nextTick,
  onDeactivated,
  onUnmounted,
  watch,
  toRaw,
  computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import api from "@/api/axios";
import dateFormat from "@/utils/dateFormat";
import Loading from "@/components/UI/Loading/src/Loading.vue";
import PostTopicBtn from "@/components/common/PostTopicBtn.vue";
import Preview from "@/components/UI/Preview";
import qs from "qs";
import PersonalMine from "@/components/personal/Mine.vue";
import Letter from "@/components/personal/Letter.vue";

import wechatLogin from "@/api/wechatLogin";
import usePageScroll, { PropsType } from "@/hooks/usePersonalScroll";
import $ from "jquery";
export default defineComponent({
  name: "Personal",
  components: {
    Loading,
    PostTopicBtn,
    Preview,
    PersonalMine,
    Letter,
  },
  data(){
    return {
      showModal: false,
      showSearch: false,
      searchText:'',
      //选择的用户组
      checkedUer:[],
      userIdList:[],
      userIds:''
    }
  },
  methods:{
    showRight(){
      this.showModal=true;
    },
    searchUser(){
      console.log(this.searchText)
      if(this.searchText==''){
        Toast({
          type: "error",
          title: '请输入好友昵称或手机号码',
        });
        return false;
      }
      this.loading=true;
      api.get("/M/Personal/findFriendByNickNameOrMobile?keyword="+this.searchText+"&type=1")
          .then((res) => {
            console.log(res)
            this.loading=false
            if (res.data.success) {
              this.userList = res.data.obj;
              if (this.userList.length == 0) {
                Toast({
                  type: "error",
                  title: '昵称或手机号为【'+this.searchText+'】的好友不存在！',
                });
              }
            } else {
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.loading=false
            console.log("err");
          });
    },
    closeModal(){
      this.showModal=false
    },
    DouserGive(){
      this.userIds='';
      const userIdList=toRaw(this.userIdList);
      userIdList.map((item: any)=>{
        if( this.userIds==''){
          this.userIds+=item
        }else {
          this.userIds=this.userIds+','+item
        }
      });
      console.log(this.userIds)
      api.get("/M/Personal/focusFriends?userIds="+this.userIds)
          .then((res) => {
            console.log(res)
            if (res.data.success) {
              Toast({
                type: "success",
                title: res.data.msg,
              });
              location.reload()
            } else {
              Toast({
                type: "success",
                title: res.data.msg,
              });
            }
            this.showModal=false;
          })
          .catch((err) => {
            console.log("err");
            this.showModal=false;
          });

    }
  },
  setup() {
    const route = useRoute();
    const id = ref(route.query.uid);

    const userData = ref(null);
    const menu = ["我的", "关注", "粉丝", "私信"];
    const currentMenu = ref("我的");
    const focusUserData = ref(null);
    const subUserData = ref(null);
    const letterUserData = ref(null);
    const loading = ref(false);
    let clearScrollFn: () => void;
    let clearScrollFc: () => void;
    let activeScroll: () => void;
    let updateShow: (param: boolean) => void;
    /**
     * 组装从【我的】接口信息中获取到的交流区信息
     */
    function mapTopicData(data: any, user: any) {
      const topic = data.topic
          ? [data.topic]
          : [
            {
              id: data.mTopicId,
              user: user,
              title: data.mTitle,
              pics: data.pics,
              summary: data.msummary,
              createTime: data.mlastPostTime,
              clickCount: data.mviews,
              likeUser: data.mList,
              isVerify: data.isVerify,
            },
          ];
      return {
        name: data.mForumName,
        data: topic,
      };
    }
    /**
     * 组装从【我的】接口信息中获取到的课堂信息
     */
    function mapCourseData(data: any) {
      return {
        name: data.mForumName,
        data: [
          {
            id: data.mCourseId,
            courseType: data.mType,
            coursePicUrl: data.mpicUrl,
            courseName: data.mTitle,
            realName: data.mRealName,
            liveStartTime: data.mlastPostTime,
            isVerify: data.isVerify,
            createUser: data.createUser,
          },
        ],
      };
    }
    /**
     * 组装从【我的】接口信息中获取到的信息
     */
    function filterFc(user: any) {
      return function (item: any) {
        switch (item.mForumName) {
          case "课堂":
            return mapCourseData(item);
          case "交流区":
            return mapTopicData(item, user);
          case "问答":
            return mapTopicData(item, user);
          default:
            return item;
        }
      };
    }
    /**
     * 获取我的【我的】
     */
    function getPersonal() {
      loading.value = true;
      axios
          .get(`/M/Personal/${id.value}`)
          .then((res) => {
            if (res.data.success) {
              userData.value = null;
              userData.value = res.data.obj;
              console.log('userData.value',userData.value)

            } else {
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }

            loading.value = false;
            return res;
          })
          .then((res) => {
            const {
              result,
              clear,
              updateShowItem,
              clearScroll,
              active,
              empty,
            } = usePageScroll(
                {
                  url: `/M/Personal/personalJson/${id.value}`,
                  filterFunc: filterFc(res.data.obj.user),
                },
                true
            );
            clearScrollFn = clear;
            clearScrollFc = clearScroll;
            activeScroll = active;
            updateShow = updateShowItem;
            (userData.value as any).myList = result;
            loading.value = false;
          })
          .catch((e) => {
            console.log("err");
            loading.value = false;
          });
    }
    /**
     * 获取我的【关注】
     */
    function getMyFollow() {
      loading.value = true;
      axios
          .get(`/M/Personal/MyFollowTopic/${id.value}`)
          .then((res) => {
            if (res.data.success) {
              focusUserData.value = null;
              res.data.obj.myList = res.data.obj.myList.map((item: any) => {
                switch (item.mForumName) {
                  case "课堂":
                    return mapCourseData(item);
                  case "交流区":
                    return mapTopicData(item, res.data.obj.user);

                  default:
                    return item;
                }
              });
              res.data.obj.userList = res.data.obj.follow;
              focusUserData.value = res.data.obj;
              console.log("focusUserData.value",focusUserData.value);
            } else {
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }
            loading.value = false;
          })
          .catch((e) => {
            console.log("err");
            loading.value = false;
          });
    }
    /**
     * 获取我的【粉丝】
     */
    function getFollowMy() {
      loading.value = true;
      axios
          .get(`/M/Personal/FollowMyTopic/${id.value}`)
          .then((res) => {
            if (res.data.success) {
              subUserData.value = null;
              res.data.obj.myList = res.data.obj.myList.map((item: any) => {
                switch (item.mForumName) {
                  case "课堂":
                    return mapCourseData(item);
                  case "交流区":
                    return mapTopicData(item, res.data.obj.user);

                  default:
                    return item;
                }
              });
              res.data.obj.userList = res.data.obj.befollow;
              subUserData.value = res.data.obj;
              console.log("subUserData.value",subUserData.value)
            } else {
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }
            loading.value = false;
          })
          .catch((e) => {
            console.log("err");
            loading.value = false;
          });
    }
    /**
     * 获取我的【私信】
     */
    function getMyLetter() {
      loading.value = true;
      axios
          .get(`/M/Personal/MyLetter/${id.value}`)
          .then((res) => {
            if (res.data.success) {
              letterUserData.value = null;
              letterUserData.value = res.data.obj;
              console.log("letterUserData.value",letterUserData.value)
            } else {
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }
            loading.value = false;
          })
          .catch((e) => {
            console.log(e);
            loading.value = false;
          });
    }

    const userInfo = inject("userInfo") as any;
    const returnLoginUid = userInfo.id;
    const login = inject("handleHide") as () => void;
    const router = useRouter();
    function toLogin() {
      router.push({
        name: "Login",
        query: {
          redirect: router.currentRoute.value.fullPath,
        },
      });
    }
    /**
     * 按照type定位页面栏目焦点【我的】、【关注】、【粉丝】、【私信】
     */
    function getDataByType(type: string) {
      switch (type) {
        case "mine":
          currentMenu.value = "我的";
          break;
        case "follow":
          currentMenu.value = "关注";
          break;
        case "fans":
          currentMenu.value = "粉丝";
          break;
        case "letter":
          currentMenu.value = "私信";
          break;
        default:
          currentMenu.value = "我的";
      }
    }
    function changeMenu(item: string) {
      currentMenu.value = item;

      switch (item) {
        case "关注":
          updateShow(false);
          getDataByType("follow");
          break;
        case "粉丝":
          updateShow(false);
          getDataByType("fans");
          break;
        case "私信":
          updateShow(false);
          getDataByType("letter");
          break;
        default:
          updateShow(true);
          getDataByType("mine");
      }
    }

    function initData() {
      getPersonal();
      //  用户登录之后 且 是自己的个人中心才可以查看 关注、粉丝、私信等
      // if (localStorage.getItem("token") && id.value == userInfo.id) {
      //修改原因，页面刷新时会丢失userInfo对象，所以判断是登录的状态就往后端请求，具体判断在后端执行
      if (localStorage.getItem("token")) {
        getMyFollow();
        getFollowMy();
        getMyLetter();
      }
    }
    getDataByType(route.query.type as string);
    //  预览大图---start
    const previewing = ref(false);
    function closePreview() {
      previewing.value = false;
    }
    function showPreview() {
      previewing.value = true;
    }
    // 预览大图---end
    /**
     * 关注/取消关注
     * @param isFocus
     * @param id
     */
    function swichFocus(isFocus: boolean, id: string) {
      const url = isFocus ? "/M/BBSServer/Follow" : "/M/BBSServer/CancelFollow";
      axios
          .post(
              url,
              qs.stringify({
                userid: id,
              }),
              {
                params: {
                  validate: true,
                },
              }
          )
          .then((res) => {
            if (res.data.success) {
              if (isFocus) {
                (userData.value as any).count = 1;
              } else {
                (userData.value as any).count = 0;
              }
              Toast({
                type: "success",
                title: res.data.msg,
              });
            } else {
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }
          })
          .catch((e) => {
            console.log("err");
          });
    }
    const isWriting = ref(false);
    const inputText = ref("");
    /**
     * 关闭发送私信的弹出层
     */
    function hideForm() {
      inputText.value = "";
      isWriting.value = false;
    }
    /**
     * 发送私信-登录判断
     */
    function handleFormShow() {
      if (userInfo.id) {
        isWriting.value = true;
      } else {
        Toast({
          type: "warning",
          title: "请先登录",
        });
        router.push({
          name: "Login",
        });
      }
    }
    /**
     * 提交私信
     */
    function submit() {
      if (!inputText.value) {
        Toast({
          type: "warning",
          title: "请先输入内容再提交哦~",
        });
        return false;
      }
      const data = qs.stringify({
        uid: userInfo.id,
        contents: inputText.value,
        reciveid: id.value,
      });
      axios
          .post("/M/Server/Letter", data)
          .then((res) => {
            if (res.data.success) {
              Toast({
                type: "success",
                title: res.data.msg,
              });
              hideForm();
            } else {
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }
          })
          .catch((err) => {
            // const message = err.message ? err.message : "未知错误，请联系管理员";
            // Toast({
            //   type: "error",
            //   title: message,
            // });
          });
    }
    const UPDATE_USERINFO = inject("UPDATE_USERINFO") as (data: object) => void;
    function handleUserInfo(data: object) {
      UPDATE_USERINFO(data);
    }
    function updateToken(token: string) {
      localStorage.setItem("token", token);
    }
    wechatLogin(route.query.code as string, route.query.state as string)
        .then((res: any) => {
          if (!route.query.uid && typeof res === "object" && res.data.success) {
            id.value = res.data.obj.id;
            handleUserInfo(res.data.obj);
          }
          initData();
        })
        .catch((e) => {
          console.log(e, "error, -----------------------");
        });
    onActivated(() => {
      const uid = sessionStorage.getItem("personal-uid");
      if ((route.query.uid && uid && route.query.uid != uid) || !uid) {
        $(window).scrollTop(0);
        id.value = route.query.uid;
        initData();
        sessionStorage.setItem("personal-uid", route.query.uid as string);
      }
    });
    watch(
        [
          () => {
            return route.query;
          },
          () => {
            return route.name;
          },
        ],
        (to, from) => {
          //  只有个人中心跳个人中心的时候才会监控路由加载数据
          if (
              (from[1] == "Personal" &&
                  (to[0] as any).uid &&
                  (to[1] as any) != "Attention" &&
                  (from[0] as any).uid != (to[0] as any).uid) ||
              from[1] == "Message"
          ) {
            updateShow(true);
            clearScrollFc && clearScrollFc();
            clearScrollFn && clearScrollFn();
            id.value = (to[0] as any).uid as string;

            isWriting.value = false;
            getDataByType((to[0] as any).type as string);
            loading.value = true;
            initData();
          }
        }
    );
    //  页面被缓存时，停止加载更多页面
    onActivated(() => {
      activeScroll && activeScroll();
    });
    onDeactivated(() => {
      clearScrollFc && clearScrollFc();
    });
    onUnmounted(() => {
      clearScrollFc && clearScrollFc();
      clearScrollFn && clearScrollFn();
    });
    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
      modal: true,
    };
    return {
      id,
      userData,
      userInfo,
      toLogin,
      currentMenu,
      menu,
      changeMenu,
      dateFormat,
      swichFocus,
      loadOption,
      loading,
      showPreview,
      previewing,
      closePreview,
      isWriting,
      inputText,
      handleFormShow,
      hideForm,
      submit,
      focusUserData,
      subUserData,
      letterUserData,
      returnLoginUid
    };
  },
});
</script>

<style lang="scss" scoped>
main {
  padding-bottom: 50px;
}
.modal-close {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  top: 0;
  border-radius: 50%;
  border: 0;
}
.modal-body-title{
  text-align: center;
  font-size: 16px;
  position: relative;
  bottom: 10px;
}
#userGiveForm > label input, #userGiveForm > label span {
  position: relative;
  margin: 0 15px;
  bottom: 10px;
}

#userGiveForm > label {
  display: block;
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  overflow: hidden;
}

.modal-backdrop {
  /* 当打开模态框时，用半透明的蒙层遮挡网页剩余内容 */
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);

}

#userGiveForm > label img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.modal-rushMessage {
  font-size: 16px;
  text-align: center;
  color: white;
  height: 130px;
  padding-top: 25px;
  margin: 0 auto;
  width: 80%;
}

.user-button {
  display: flex;
  width: 50%;
  margin: 0 auto;
  justify-content: space-around;
}

#userGiveForm {
  height: 310px;
  overflow: auto;
}

.user_btn {
  color: #fff;
  font-size: 14px;
  margin-top: 7px;
  background: #e00025;
  padding: 4px 15px;
  border-radius: 6px;
}

.modal-body {
  background: white;
  border-radius: 8px;
  z-index: 99;
  width: 320px;
  height: 430px;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 20px 5px;
  font-size: 14px;
}

.modal-body ul {
  text-align: left;
  font-size: 12px;
  height: 150px;
  overflow-y: auto;
}

.modal-body ul li {
  margin: 8px auto;
  overflow: hidden;
  white-space: nowrap;
  width: 85%;
  text-overflow: ellipsis;
}

.modal-body ul li span {
  color: red;
}

.like-user-search{
  padding: 0 20px;
  line-height: 32px;
}
.like-user-search span{
  display: inline-block;
  width: 20%;
  font-size:14px;
  text-align: center;
  height: 0.8rem;
  background: #df0024;
  padding: 0 0.26667rem;
  border-radius: 0.37333rem;
  line-height: 0.8rem;
  /* float: right; */
  position: relative;
  color: white;
  bottom: 1px;
  margin-left: 1%;
  right: 0;
}
.like-user-search input{
  width: 78%;
  height: 0.8rem;
  background-color: #ffffff;
  border: solid 0.02667rem #dddddd;
  border-radius: 12px;
  padding: 0 0.4rem;
  position: relative;
  -webkit-appearance: none;
}
.header {
  width: 100%;
  height: 235px;
  background: url("../assets/img/personal/bg.png") no-repeat
  center top;
  background-size: contain;
  padding-top: 41px;
  box-sizing: border-box;
  position: relative;
  border-bottom: 6px solid #f4f4f4;
}

.user-info {
  color: #444;
  font-size: 14px;
  text-align: center;
}

.avatar {
  width: 86px;
  height: 86px;
  border: 2.5px solid #fff;
  margin: 0 auto;
  display: block;
  border-radius: 50%;
}

.private-chat {
  position: absolute;
  bottom: 5%;
  left:50%;
  font-size: 12.5px;
  color: #000;
  display: flex;
  align-items: center;
  border:1px solid #ccc;
  border-radius: 0.73333rem;
  text-align: center;
  width: 75px;
  height: 27.5px;
  padding-left: 25px;
}

.private-chat img {
  width: 25px;
  height: 25px;
  margin-right: 3px;
  /* vertical-align: middle; */
}

.nick-name {
  margin-bottom: 12.5px;
}

.other-info {
  color: #666;
}

.other-info span {
  display: inline-block;
  margin: 0 8px;
}

.focus {
  border: none;
  outline: none;
  background-color: transparent;
  width: 72.5px;
  height: 27.5px;
  position: absolute;
  bottom: 5%;
  left: 25%;
  border-radius: 27.5px;
  color: #df0024;
  font-size: 12px;
  text-align: center;
  /* line-height: 27.5px; */
  display: flex;
  border: 1px solid #df0024;
  align-items: center;
  justify-content: center;
}

.focus img {
  width: 10px;
  height: 10px;
  margin-right: 3px;
  /* vertical-align: middle; */
}

.dynamic-wapper {
  border-top: 1px solid #f4f4f4;
}

.dynamic-title {
  padding-left: 20px;
  border-bottom: 6px solid #f4f4f4;
  box-sizing: border-box;
  /*height: 41px;*/
  /*line-height: 33.75px;*/
  position: relative;
  color: #444444;
  font-size: 16px;
  padding-right: 10px;
  padding-top: 7.5px;
}

.dynamic-title p {
  color: #999999;
  line-height: 22.5px;
  font-size: 15px;
  padding-bottom: 0.3rem;
  margin-top: 3.75px;
  word-break: break-all;
  padding-right: 0.1rem;
}

.dynamic-title::after {
  content: "";
  position: absolute;
  left: 10px;
  top: 22px;
  width: 1.5px;
  background-color: #df0024;
  height: 12.5px;
  transform: translateY(-50%);
}

/*关注个人*/

.post-follow-title {
  text-align: center;
  font-size: 15px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}

.post-follow-active a {
  border-bottom: 2px solid #df0024;
  color: #df0024;
}

.post-follow-before {
  position: relative;
}

.post-follow-before::before {
  content: "";
  display: block;
  position: absolute;
  left: -33.75px;
  top: 3.75px;
  width: 2.344px;
  height: 15px;
  background: #ccc;
}

.post-like-user {
  padding: 15px 20px 23px 20px;
  display: flex;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;

  height: 128px;

  overflow-x: auto;
}

.post-like-list {
  display: inline-block;
  width: 79.987px;
}

.post-like-list a {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.post-like-list span {
  display: block;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin: 0 auto;
}

.post-like-list span img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-like-list p {
  width: 100%;
  text-align: center;
  margin-top: 11.25px;
  color: #444444;
  font-size: 14px;
  line-height: 18.75px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*关注个人 end*/

.empty {
  color: #9f9f9f;
  font-size: 13px;
  padding-top: 79.987px;
  text-align: center;
}

.empty img {
  width: 132px;
  height: 103.988px;
  display: block;
  margin: 0 auto;

  margin-bottom: 15px;
}

.edit {
  position: fixed;
  width: 40px;
  height: 40px;
  right: 10px;
  top: 80%;
  margin-top: -20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  z-index: 8;
}

.edit img {
  width: 100%;
  height: 100%;
  display: block;
}

.private-letter {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 133.988px;
  width: 100%;
  z-index: 10000;
  background-color: #fff;
  border-top: 1px solid #eee;
  padding: 0 10px;
  box-sizing: border-box;
}
.private-letter-operation {
  overflow: hidden;
  padding-top: 20px;
  font-size: 14px;
  color: #444444;
  box-sizing: border-box;
  margin-bottom: 4.988px;
}

.cancel {
  float: left;
}

.confirm {
  float: right;
  color: #df0024;
}

#letter-content {
  width: 100%;
  box-sizing: border-box;
  padding: 7.5px;
  color: #444444;
  font-size: 14px;
  height: 75px;
  background: #f4f4f4;
  border-radius: 4.988px;
  margin-top: 4.988px;
  border: none;
  outline: none;
  color: #444;
  font-size: 14px;
}

#letter-content::-webkit-input-placeholder {
  color: #444444;
  font-size: 14px;
}

.letter_list_div {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px 10px;
  border-bottom: 1px solid #eee;
}

.letter-pic {
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.letter-pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.letter-text {
  float: left;
  margin-left: 19px;
  width: 190px;
  padding-top: 4.988px;
}

.letter-h {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #444444;
  font-size: 15px;
}

.letter-p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #999999;
  font-size: 14px;
  margin-top: 10px;
}

.letter-rr {
  float: right;
  text-align: right;
  padding-top: 4.988px;
}

.letter-date {
  color: #666666;
  font-size: 14px;
}

.cancel-focus {
  border: none;
  outline: none;
  background-color: transparent;
  width: 75px;
  height: 27.5px;
  position: absolute;
  bottom: 5%;
  left: 25%;
  border-radius: 27.5px;
  color: #ccc;
  font-size: 12px;
  text-align: center;
  line-height: 27.5px;
  border: 1px solid #ccc;
  padding: 0;
}

.no-likes {
  font-size: 13px;
  text-align: center;
  margin: 0 auto;
  line-height: 128px;
  color: #333;
  text-align: center;
}

.form {
  border-top: 1px solid #eee;
  position: fixed;
  width: 100%;
  left: 0;
  height: 133px;
  bottom: 50px;
  background-color: #fff;
  padding: 7.5px 10px;
  box-sizing: border-box;
  z-index: 10;
}
.operation {
  height: 35px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.operation div {
  float: left;
  width: 50%;
  font-size: 14px;
  color: #444444;
  box-sizing: border-box;
}

.operation .confirm {
  float: right;
  color: #df0024;
  text-align: right;
}

textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 7.5px;
  color: #444444;
  font-size: 14px;
  height: 75px;
  background: #f4f4f4;
  border-radius: 5px;
  margin-top: 5px;
  border: none;
  outline: none;
}
.tjhy{
  display: inline-block;
  width:30px;
  height: 30px;
  position: absolute;
  right: 3%;
  top:0;
}
.tjhy img{
  width:100%;
}
</style>
