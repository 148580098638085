<template>
  <router-link :to="{ name: 'PostTopic', query: { postType: 0 } }" class="edit">
    <!-- <img src="http://www.bncc.org.cn/static/m/images/icon34.png" alt="发帖" /> -->
    <img src="@/assets/img/personal/icon34.png" alt="发帖" />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "PostTopicBtn",
});
</script>

<style lang="scss" scoped>
.edit {
  position: fixed;
  width: 40px;
  height: 40px;
  right: 10px;
  top: 80%;
  margin-top: -20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  z-index: 10;
}

.edit img {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
